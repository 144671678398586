@use '../reset';
@use '../vars';
@use '../mixins';
@use '../fonts';
@use '../cols';
@use '../general';
@use '../header';
@use '../buttons';
@use '../checkboxes';
@use '../form-errors';
@use '../icons';
@use '../flashes';

.login-body {
  background: url("../../images/backbins.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .header a.logo {
    float: none;
    display: block;
    img {
      display: block !important;
      margin: 0 auto;
    }
  }
}


.login-content-wrapper {
  @include mixins.border-radius(3px);
  top: 100px;
  left: 50%;
  margin-left: -200px;
  position: absolute;
  width: 400px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  @media screen and (max-width: vars.$break-s) {
    margin-left: -160px;
    width: 320px;
  }
  .login-error {
    @include mixins.border-radius(3px);
    @include mixins.box-shadow(0 0 1px rgba(0, 0, 0, 0.1));
    font-size: 1.6rem;
    padding: 0 10px;
    max-width: 400px;
    margin-bottom: 10px;
    text-align: center;
    height: auto;
    .close-icon { display: none; }
    a {
      font-weight: bold;
      text-decoration: underline;
      color: #FFF;
      &:hover { text-decoration: none; }
    }
  }

  .login-form-container {
    @include mixins.border-radius(3px);
    @include mixins.box-shadow(0 0 1px rgba(0, 0, 0, 0.1));
    background-color: #fafafa;
    overflow: hidden;

    h1 {
      background-color: vars.$green;
      letter-spacing: 1px;
      margin-bottom: 20px;
      padding: 15px 20px 12px;
      color: #fff;
      font-size: 2.4rem;
      text-align: center;
      font-weight: normal;
    }
    form {
      padding: 0 20px;
      margin-bottom: 20px;
    }
    .input-row {
      position: relative;
      label {
        display: none;
        &.rememberme {
          display: block;
          font-size: 1.4rem;
          line-height: 3rem;
          margin-bottom: 10px;
          margin-left: 5px;
          padding-left: 30px !important;
        }
        &.error {
          font-size: 1.4rem;
          right: 14px;
          bottom: 55px;
        }
      }
      input {
        @include mixins.border-radius(3px);
        border: 1px solid vars.$e3;
        font-size: 1.6rem;
        padding: 15px 10px;
        width: 100%;
        margin-bottom: 10px;
        &:focus {
          border: 1px solid #d3d3d3;
        }
        &#username,
        &#password,
        &.password {
          background-position: right center;
          background-repeat: no-repeat;
          padding-right: 36px;
        }
        &#username {
          background-image: url("../../../public/images/icons/login-user-icon.png");
        }
        &#password, &.password {
          background-image: url("../../../public/images/icons/login-pass-icon.png");
        }
      }
      .btn {
        width: 100%;
        padding: 10px;
        text-align: center;
        font-size: 1.6rem;
        //height: 40px;
        font-weight: normal;
        letter-spacing: 1px;
        &::after {
          display: none;
        }
      }
    }
    &.reset-form {
      margin-top: -250px;
    }
  }
  .reset-msg {
    color: #666;
    margin-bottom: 10px;
    &.padding {
      margin-bottom: 20px;
      padding: 0 20px;
    }
    p {
      padding: 0 0 10px;
    }
  }
  .voorwaarden {
    color: #999;
    font-size: 1rem;
    margin: 15px 0;
    text-align: center;
    width: 100%;
    a {
      color: vars.$green;
      text-decoration: none;
      &:hover {
        color: vars.$green-hover;
      }
    }
  }
  .link-btn {
    @include mixins.border-radius(3px);
    background-color: vars.$green;
    color: #fff;
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 10px;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    &:hover {
      background-color: vars.$green-hover;
    }
  }
  .msg-wrapper {
    @include mixins.border-radius(3px);
    background-color: #fafafa;
    margin-top: 10px;
    padding: 20px;
    .msg-nl, .msg-fr {
      font-size: 1.2rem;
    }
    .msg-nl {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid vars.$e3;
    }
  }
}

.compatiblebrowser {
  display: none;
  text-align: center;
}

.reset-link{
  text-align: right;

  a {
    font-size: 14px;
    text-decoration: none;
    color: #006a2e;
    line-height: 27px;
  }
}

.col-12 {
  float: inherit;
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
}