$green: #006a2e;
$green-hover: #008037;

$red: #ff2600;
$red-hover: #ff4726;
$light-red: #ff4726;
$yellow: #ffba00;
$blue: #00aaff;
$blue-hover: #00aaff;

$orange: #ffba00;
$orange-hover: #ffba00;

$orange: #f79646;
$blue-acc: #4f81bd;


$light-yellow: #fab700;

$e3: #e3e3e3;
$f3: #f3f3f3;
$white: #ffffff;

/**
 * Media Queries
 * 1 em = 16 pixels
 */
$break-xl: 100em; //  100 em = 1600 px
$break-l: 90.625em; //  90.625 em = 1450 px
$break-m: 64em; //      64 em = 1024 px
$break-s: 58.75em; //   58.75 em =  940 px
$break-xs: 36.25em; //   36.25 em =  580 px
$break-xxs: 21.25em; //   21.25 em =  340 px

/**
 * Dark navigation colors
 */
$navback: #404040;
$navactive: #333333;
$navdashtext: #5c5c5c;
$yellowbar: #ffba00;