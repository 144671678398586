@use 'vars';
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin box-shadow($args...) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform ($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin animation-delay($value) {
  -webkit-animation-delay: $value;
  animation-delay: $value;
}

/***** Media Query Mixin *****/
@mixin breakpoint($point) {
  @if $point == break-xl {
    @media (max-width: vars.$break-xl) {
      @content;
    }
  } @else if $point == break-l {
    @media (max-width: vars.$break-l) {
      @content;
    }
  } @else if $point == break-m {
    @media (max-width: vars.$break-m) {
      @content;
    }
  } @else if $point == break-s {
    @media (max-width: vars.$break-s) {
      @content;
    }
  } @else if $point == break-xs {
    @media (max-width: vars.$break-xs) {
      @content;
    }
  } @else if $point == break-xxs {
    @media (max-width: vars.$break-xxs) {
      @content;
    }
  }
}