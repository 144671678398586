@use 'vars';
@use 'mixins';

.flash-notice {
  @include mixins.border-radius(3px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;

  &.success {
    background-color: vars.$green;
  }
  &.warning {
    background-color: vars.$yellow;
  }
  &.error {
    background-color: vars.$red;
  }
  &.info {
    background-color: vars.$blue;
  }
  &.opentasks {
    cursor: pointer;
  }
  .flash-icon, .flash-close {
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .flash-label {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    flex-grow: 1;
  }
  .flash-close {
    @include mixins.transition(background-color 0.2s ease);
    color: #FFF;
    text-decoration: none;
    border-left: 1px solid rgba(255, 255, 255, 0.25);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    .icon {
      font-size: 1.8rem;
    }
  }
}
.not-important-flashes {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 400px;
  margin-bottom: -10px;

  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}