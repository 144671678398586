@use 'mixins';
@use 'vars';

.header {
  background-color: #FFF;
  border-bottom: 1px solid #e2e2e2;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  &.lock{
    display: none;
  }
  a.logo {
    float: left;
    padding: 0;
    margin-right: 10px;
    border-left: 0;
    &:hover {
      background-color: #f6f6f6;
    }
    img {
      display: block;
      height: 60px;
      &.header-small-logo {
        display: none;
      }
    }
  }
  a {
    border-left: 1px solid #e2e2e2;
    @include mixins.transition(background 0.2s ease);
    &.active,
    &:hover {
      background-color: #f6f6f6;
    }
  }
  .header-links {
    float: right;
    .welcome-msg {
      padding: 0 20px;
    }
    .internal-info-toggle {
      padding: 0 20px;

      label {
        line-height: 30px;
      }
    }
    .quickrfq-btn {
      @include mixins.border-radius(3px);
      background-color: vars.$yellow;
      color: #fff;
      padding: 10px;
      line-height: 16px;
      height: auto;
      margin: 10px;

      span {
        line-height: 2rem;
        height: auto;
        color: vars.$white;
      }
    }
    a, span {
      float: left;
      height: 59px;
      display: block;
      padding: 0 10px;
      font-size: 1.4rem;
      color: #666666;
      text-decoration: none;
      line-height: 60px;
      .header-link-text { padding: 0 5px; }
      .icon {
        font-size: 2rem;
        padding: 0 5px;
      }
    }

    @media screen and (max-width: vars.$break-s) {
      display: none;
    }
    @media screen and (max-width: vars.$break-xl) {
      .welcome-msg {
        display: none;
      }
    }
    @media screen and (max-width: vars.$break-l) {
      span.header-link-text {
        display: none;
      }
    }
    @media screen and (max-width: vars.$break-xl) {
       .welcome-msg {
         display: none;
       }
    }
  }
  .meta-menu-btn {
    display: none;
    float: right;
    height: 59px;
    width: 60px;
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 60px;
    padding: 15px;
    @media screen and (max-width: vars.$break-m) {
      display: block;
    }
    span {
      @include mixins.border-radius(3px);
      background-color: #666666;
      margin-top: 5px;
      height: 4px;
      display: block;
    }
  }
  @media screen and (max-width: vars.$break-m) {
    a.logo {
      img {
        display: none;

        &.header-small-logo {
          display: block;
          height: 60px;
        }
      }
    }
  }
}