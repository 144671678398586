@use 'vars';

input[type=checkbox] {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  opacity: 0 !important;
  visibility: hidden;
  pointer-events: none;

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-position: 3px 0px;
    }
  }
}

input[type=checkbox] + label {
  padding-left: 28px !important;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  background-repeat: no-repeat;
  background-position: 3px 0px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 3px;
  background-image: url("../images/checkboxes.png");
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-position: 3px -28px;
  }
  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-position: 3px 0px;
    }
  }
}

input[type=checkbox]:checked + label {
  background-position: 3px -56px;
}
input[type=checkbox]:checked:disabled + label {
  opacity: 1;
  background-position: 3px -112px;
}
input[type=checkbox]:checked:disabled:hover + label {
  opacity: 1;
  background-position: 3px -112px;
}

input[type=checkbox]:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
  background-position: 3px 0px;
}

input[type=checkbox]:disabled:hover + label {
  background-position: 3px 0px;
}

input.inherit-parent[type=checkbox]:checked + label {
  background-position: 3px -112px;
}

input.disabled[type=checkbox]:checked + label {
  background-position: 3px -112px;
}

.passwordCheck {
  .icon-check {
    color: vars.$green;
  }
  .icon-error {
    color: vars.$red;
  }
}