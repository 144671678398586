@use 'vars';
@use 'mixins';

.icon {
  text-decoration: none !important;
}

span, a {
  &.text-icon {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    height: 30px;
    margin-left: 7px;
    width: 30px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
  }
  &.big-icon {
    color: vars.$green;
    font-size: 2rem;
    height: 24px;
    line-height: 22px;
    margin: 0 5px;
    width: 24px;
    text-decoration: none;

    &.yellow-icon {
      color: vars.$yellow;
    }
    &.red-icon {
      color: vars.$red;
    }
    &.grey-icon {
      color: vars.$e3;
    }
  }

  &.circled-icon {
    @include mixins.border-radius(12px);
    background-color: vars.$green;
    color: #FFF;
    float: right;
    font-size: 1.4rem;
    height: 24px;
    line-height: 22px;
    margin-left: 5px;
    text-align: center;
    width: 24px;
    text-decoration: none;

    &.yellow-icon {
      background-color: vars.$yellow;
    }
    &.red-icon {
      background-color: vars.$red;
    }
    &.grey-icon {
      background-color: vars.$e3;
    }
  }
}

.icon-question:before {
  content: "?";
  font-family: "Calibri", sans-serif !important;
  font-size: 1.5rem;
  font-weight: bold;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?q4preb');
  src:  url('../fonts/icomoon.eot?q4preb#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?q4preb') format('truetype'),
  url('../fonts/icomoon.woff?q4preb') format('woff'),
  url('../fonts/icomoon.svg?q4preb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\ea10";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-rss:before {
  content: "\f09e";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-cloud-download:before {
  content: "\f0ed";
}
.icon-chain-broken:before {
  content: "\f127";
}
.icon-unlink:before {
  content: "\f127";
}
.icon-dropbox:before {
  content: "\f16b";
}
.icon-question-circle-o:before {
  content: "\f29c";
}
.icon-close:before {
  content: "\e900";
}
.icon-floppy-o:before {
  content: "\e901";
}
.icon-calendar-check-o:before {
  content: "\e902";
}
.icon-calendar-times-o:before {
  content: "\e903";
}
.icon-calendar-minus-o:before {
  content: "\e904";
}
.icon-calendar-plus-o:before {
  content: "\e905";
}
.icon-calendar-o:before {
  content: "\e906";
}
.icon-add-inverted:before {
  content: "\e907";
}
.icon-add:before {
  content: "\e908";
}
.icon-attention-inverted:before {
  content: "\e909";
}
.icon-attention:before {
  content: "\e90a";
}
.icon-browse:before {
  content: "\e90b";
}
.icon-bullhorn:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-cart:before {
  content: "\e90e";
}
.icon-check-inverted:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-clip:before {
  content: "\e911";
}
.icon-clock:before {
  content: "\e912";
}
.icon-cloud_download:before {
  content: "\e913";
}
.icon-cloud_upload:before {
  content: "\e914";
}
.icon-contacts:before {
  content: "\e915";
}
.icon-current:before {
  content: "\e916";
}
.icon-document:before {
  content: "\e917";
}
.icon-down-inverted:before {
  content: "\e918";
}
.icon-down:before {
  content: "\e919";
}
.icon-error-inverted:before {
  content: "\e91a";
}
.icon-error:before {
  content: "\e91b";
}
.icon-funnel:before {
  content: "\e91c";
}
.icon-globus:before {
  content: "\e91d";
}
.icon-heart:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-in:before {
  content: "\e920";
}
.icon-info-inverted:before {
  content: "\e921";
}
.icon-info:before {
  content: "\e922";
}
.icon-inkpen:before {
  content: "\e923";
}
.icon-layers:before {
  content: "\e924";
}
.icon-left-inverted:before {
  content: "\e925";
}
.icon-left:before {
  content: "\e926";
}
.icon-lightning:before {
  content: "\e927";
}
.icon-link1:before {
  content: "\e928";
}
.icon-location:before {
  content: "\e929";
}
.icon-lock:before {
  content: "\e92a";
}
.icon-mail:before {
  content: "\e92b";
}
.icon-message:before {
  content: "\e92c";
}
.icon-new_post:before {
  content: "\e92d";
}
.icon-out:before {
  content: "\e92e";
}
.icon-paper_plane:before {
  content: "\e92f";
}
.icon-pencil:before {
  content: "\e930";
}
.icon-phone:before {
  content: "\e931";
}
.icon-pin-1:before {
  content: "\e932";
}
.icon-pin:before {
  content: "\e933";
}
.icon-portfolio:before {
  content: "\e934";
}
.icon-printer:before {
  content: "\e935";
}
.icon-profile:before {
  content: "\e936";
}
.icon-recycle_bin:before {
  content: "\e937";
}
.icon-remove-inverted:before {
  content: "\e938";
}
.icon-remove:before {
  content: "\e939";
}
.icon-right-inverted:before {
  content: "\e93a";
}
.icon-right:before {
  content: "\e93b";
}
.icon-sandglass:before {
  content: "\e93c";
}
.icon-search:before {
  content: "\e93d";
}
.icon-settings:before {
  content: "\e93e";
}
.icon-share:before {
  content: "\e93f";
}
.icon-star1:before {
  content: "\e940";
}
.icon-stats:before {
  content: "\e941";
}
.icon-storage:before {
  content: "\e942";
}
.icon-up-inverted:before {
  content: "\e943";
}
.icon-up:before {
  content: "\e944";
}
.icon-update:before {
  content: "\e945";
}
.icon-burger:before {
  content: "\e946";
}
.icon-business:before {
  content: "\e947";
}
.icon-euro:before {
  content: "\e948";
}
.icon-eq:before {
  content: "\e949";
}
.icon-label:before {
  content: "\e94a";
}
.icon-off:before {
  content: "\e94b";
}
.icon-yes:before {
  content: "\e94c";
}
.icon-stop:before {
  content: "\e94d";
}
.icon-rfq:before {
  content: "\e94e";
}
.icon-headset_mic:before {
  content: "\e951";
}
.icon-newspaper:before {
  content: "\e94f";
}
.icon-address-book:before {
  content: "\e950";
}
.icon-bubbles3:before {
  content: "\e96f";
}
.icon-cog:before {
  content: "\e994";
}
.icon-star-inverted:before {
  content: "\f005";
}
