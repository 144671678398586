@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri_light.eot');
  src: url('../fonts/calibri_light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/calibri_light.svg#Calibri Light') format('svg'),
       url('../fonts/calibri_light.woff2') format('woff2'),
       url('../fonts/calibri_light.woff') format('woff'),
       url('../fonts/calibri_light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri_light_italic.eot');
  src: url('../fonts/calibri_light_italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/calibri_light_italic.svg#Calibri Light Italic') format('svg'),
       url('../fonts/calibri_light_italic.woff2') format('woff2'),
       url('../fonts/calibri_light_italic.woff') format('woff'),
       url('../fonts/calibri_light_italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri.eot');
  src: url('../fonts/calibri.eot?#iefix') format('embedded-opentype'),
       url('../fonts/calibri.svg#Calibri') format('svg'),
       url('../fonts/calibri.woff2') format('woff2'),
       url('../fonts/calibri.woff') format('woff'),
       url('../fonts/calibri.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri_bold.eot');
  src: url('../fonts/calibri_bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/calibri_bold.svg#Calibri Bold') format('svg'),
       url('../fonts/calibri_bold.woff2') format('woff2'),
       url('../fonts/calibri_bold.woff') format('woff'),
       url('../fonts/calibri_bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}