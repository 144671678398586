@use 'vars';
@use 'mixins';

.errors {
  position: absolute;
  right: 0;
  .error {
    @include mixins.border-radius(3px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
    color: #FFF;
    background-color: vars.$red;
    display: block;
    height: 30px;
    line-height: 30px;
    margin-left: 10px;
    padding: 0 10px;
    &::after {
      border-bottom: 5px solid transparent;
      border-right: 10px solid vars.$red;
      border-top: 5px solid transparent;
      clear: both;
      content: "";
      height: 0;
      left: 1%;
      position: absolute;
      top: 32%;
      width: 0;
    }
  }
}

.input-form {
  .input-row, .form-group {
    &:has(.error) {
      position: relative;
    }
  }

  label.error {
    @include mixins.border-radius(3px);
    @include mixins.box-shadow(0 0 2px rgba(0, 0, 0, 0.2));
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: vars.$red;
    bottom: 20px;
    color: white;
    height: 30px;
    line-height: 32px;
    padding: 0px 5px;
    position: absolute;
    right: 30px;
    text-align: center;
    width: auto;
    z-index: 1;
    font-weight: normal;
    &:after {
      content: '';
      position: absolute;
      top: 85%;
      right: 5px;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-top: 10px solid vars.$red;
      border-right: 0px solid transparent;
      border-left: 10px solid transparent;
    }
  }
}