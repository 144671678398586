@use 'mixins';

[class*=col-] {
  min-height: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.col-12 { width: 100% !important; }
.col-11 { width: 91.66666667% !important; }
.col-10 { width: 83.33333333% !important; }
.col-9 { width: 75% !important; }
.col-8 { width: 66.66666667% !important; }
.col-7 { width: 58.33333333% !important; }
.col-6 { width: 50% !important; }
.col-5 { width: 41.66666667% !important; }
.col-4 { width: 33.33333333% !important; }
.col-3 { width: 25% !important; }
.col-2 { width: 16.66666667% !important; }
.col-1 { width: 8.33333333% !important; }

.col-lg-12 { @include mixins.breakpoint(break-l) { width: 100% !important; } }
.col-lg-11 { @include mixins.breakpoint(break-l) { width: 91.66666667% !important; } }
.col-lg-10 { @include mixins.breakpoint(break-l) { width: 83.33333333% !important; } }
.col-lg-9 { @include mixins.breakpoint(break-l) { width: 75% !important; } }
.col-lg-8 { @include mixins.breakpoint(break-l) { width: 66.66666667% !important; } }
.col-lg-7 { @include mixins.breakpoint(break-l) { width: 58.33333333% !important; } }
.col-lg-6 { @include mixins.breakpoint(break-l) { width: 50% !important; } }
.col-lg-5 { @include mixins.breakpoint(break-l) { width: 41.66666667% !important; } }
.col-lg-4 { @include mixins.breakpoint(break-l) { width: 33.33333333% !important; } }
.col-lg-3 { @include mixins.breakpoint(break-l) { width: 25% !important; } }
.col-lg-2 { @include mixins.breakpoint(break-l) { width: 16.66666667% !important; } }
.col-lg-1 { @include mixins.breakpoint(break-l) { width: 8.33333333% !important; } }

.col-md-12 { @include mixins.breakpoint(break-m) { width: 100% !important; } }
.col-md-11 { @include mixins.breakpoint(break-m) { width: 91.66666667% !important; } }
.col-md-10 { @include mixins.breakpoint(break-m) { width: 83.33333333% !important; } }
.col-md-9 { @include mixins.breakpoint(break-m) { width: 75% !important; } }
.col-md-8 { @include mixins.breakpoint(break-m) { width: 66.66666667% !important; } }
.col-md-7 { @include mixins.breakpoint(break-m) { width: 58.33333333% !important; } }
.col-md-6 { @include mixins.breakpoint(break-m) { width: 50% !important; } }
.col-md-5 { @include mixins.breakpoint(break-m) { width: 41.66666667% !important; } }
.col-md-4 { @include mixins.breakpoint(break-m) { width: 33.33333333% !important; } }
.col-md-3 { @include mixins.breakpoint(break-m) { width: 25% !important; } }
.col-md-2 { @include mixins.breakpoint(break-m) { width: 16.66666667% !important; } }
.col-md-1 { @include mixins.breakpoint(break-m) { width: 8.33333333% !important; } }
.col-md-0 {
  @include mixins.breakpoint(break-m) { display: none; }
  @include mixins.breakpoint(break-xs) {
    display: block;
    width: 100%;
    height: 1px;
    margin: 2em 0;
  }
}

.col-sm-12 { @include mixins.breakpoint(break-s) { width: 100% !important; } }
.col-sm-11 { @include mixins.breakpoint(break-s) { width: 91.66666667% !important; } }
.col-sm-10 { @include mixins.breakpoint(break-s) { width: 83.33333333% !important; } }
.col-sm-9 { @include mixins.breakpoint(break-s) { width: 75% !important; } }
.col-sm-8 { @include mixins.breakpoint(break-s) { width: 66.66666667% !important; } }
.col-sm-7 { @include mixins.breakpoint(break-s) { width: 58.33333333% !important; } }
.col-sm-6 { @include mixins.breakpoint(break-s) { width: 50% !important; } }
.col-sm-5 { @include mixins.breakpoint(break-s) { width: 41.66666667% !important; } }
.col-sm-4 { @include mixins.breakpoint(break-s) { width: 33.33333333% !important; } }
.col-sm-3 { @include mixins.breakpoint(break-s) { width: 25% !important; } }
.col-sm-2 { @include mixins.breakpoint(break-s) { width: 16.66666667% !important; } }
.col-sm-1 { @include mixins.breakpoint(break-s) { width: 8.33333333% !important; } }

.col-xs-12 { @include mixins.breakpoint(break-xs) { width: 100% !important; } }
.col-xs-11 { @include mixins.breakpoint(break-xs) { width: 91.66666667% !important; } }
.col-xs-10 { @include mixins.breakpoint(break-xs) { width: 83.33333333% !important; } }
.col-xs-9 { @include mixins.breakpoint(break-xs) { width: 75% !important; } }
.col-xs-8 { @include mixins.breakpoint(break-xs) { width: 66.66666667% !important; } }
.col-xs-7 { @include mixins.breakpoint(break-xs) { width: 58.33333333% !important; } }
.col-xs-6 { @include mixins.breakpoint(break-xs) { width: 50% !important; } }
.col-xs-5 { @include mixins.breakpoint(break-xs) { width: 41.66666667% !important; } }
.col-xs-4 { @include mixins.breakpoint(break-xs) { width: 33.33333333% !important; } }
.col-xs-3 { @include mixins.breakpoint(break-xs) { width: 25% !important; } }
.col-xs-2 { @include mixins.breakpoint(break-xs) { width: 16.66666667% !important; } }
.col-xs-1 { @include mixins.breakpoint(break-xs) { width: 8.33333333% !important; } }

.offset-12 { margin-left: 100% !important; }
.offset-11 { margin-left: 91.66666667% !important; }
.offset-10 { margin-left: 83.33333333% !important; }
.offset-9 { margin-left: 75% !important; }
.offset-8 { margin-left: 66.66666667% !important; }
.offset-7 { margin-left: 58.33333333% !important; }
.offset-6 { margin-left: 50% !important; }
.offset-5 { margin-left: 41.66666667% !important; }
.offset-4 { margin-left: 33.33333333% !important; }
.offset-3 { margin-left: 25% !important; }
.offset-2 { margin-left: 16.66666667% !important; }
.offset-1 { margin-left: 8.33333333% !important; }

.offset-lg-12 { @include mixins.breakpoint(break-l) { margin-left: 100% !important; } }
.offset-lg-11 { @include mixins.breakpoint(break-l) { margin-left: 91.66666667% !important; } }
.offset-lg-10 { @include mixins.breakpoint(break-l) { margin-left: 83.33333333% !important; } }
.offset-lg-9 { @include mixins.breakpoint(break-l) { margin-left: 75% !important; } }
.offset-lg-8 { @include mixins.breakpoint(break-l) { margin-left: 66.66666667% !important; } }
.offset-lg-7 { @include mixins.breakpoint(break-l) { margin-left: 58.33333333% !important; } }
.offset-lg-6 { @include mixins.breakpoint(break-l) { margin-left: 50% !important; } }
.offset-lg-5 { @include mixins.breakpoint(break-l) { margin-left: 41.66666667% !important; } }
.offset-lg-4 { @include mixins.breakpoint(break-l) { margin-left: 33.33333333% !important; } }
.offset-lg-3 { @include mixins.breakpoint(break-l) { margin-left: 25% !important; } }
.offset-lg-2 { @include mixins.breakpoint(break-l) { margin-left: 16.66666667% !important; } }
.offset-lg-1 { @include mixins.breakpoint(break-l) { margin-left: 8.33333333% !important; } }

.offset-md-12 { @include mixins.breakpoint(break-m) { margin-left: 100% !important; } }
.offset-md-11 { @include mixins.breakpoint(break-m) { margin-left: 91.66666667% !important; } }
.offset-md-10 { @include mixins.breakpoint(break-m) { margin-left: 83.33333333% !important; } }
.offset-md-9 { @include mixins.breakpoint(break-m) { margin-left: 75% !important; } }
.offset-md-8 { @include mixins.breakpoint(break-m) { margin-left: 66.66666667% !important; } }
.offset-md-7 { @include mixins.breakpoint(break-m) { margin-left: 58.33333333% !important; } }
.offset-md-6 { @include mixins.breakpoint(break-m) { margin-left: 50% !important; } }
.offset-md-5 { @include mixins.breakpoint(break-m) { margin-left: 41.66666667% !important; } }
.offset-md-4 { @include mixins.breakpoint(break-m) { margin-left: 33.33333333% !important; } }
.offset-md-3 { @include mixins.breakpoint(break-m) { margin-left: 25% !important; } }
.offset-md-2 { @include mixins.breakpoint(break-m) { margin-left: 16.66666667% !important; } }
.offset-md-1 { @include mixins.breakpoint(break-m) { margin-left: 8.33333333% !important; } }


.offset-sm-12 { @include mixins.breakpoint(break-s) { margin-left: 100% !important; } }
.offset-sm-11 { @include mixins.breakpoint(break-s) { margin-left: 91.66666667% !important; } }
.offset-sm-10 { @include mixins.breakpoint(break-s) { margin-left: 83.33333333% !important; } }
.offset-sm-9 { @include mixins.breakpoint(break-s) { margin-left: 75% !important; } }
.offset-sm-8 { @include mixins.breakpoint(break-s) { margin-left: 66.66666667% !important; } }
.offset-sm-7 { @include mixins.breakpoint(break-s) { margin-left: 58.33333333% !important; } }
.offset-sm-6 { @include mixins.breakpoint(break-s) { margin-left: 50% !important; } }
.offset-sm-5 { @include mixins.breakpoint(break-s) { margin-left: 41.66666667% !important; } }
.offset-sm-4 { @include mixins.breakpoint(break-s) { margin-left: 33.33333333% !important; } }
.offset-sm-3 { @include mixins.breakpoint(break-s) { margin-left: 25% !important; } }
.offset-sm-2 { @include mixins.breakpoint(break-s) { margin-left: 16.66666667% !important; } }
.offset-sm-1 { @include mixins.breakpoint(break-s) { margin-left: 8.33333333% !important; } }

.offset-xs-12 { @include mixins.breakpoint(break-xs) { margin-left: 100% !important; } }
.offset-xs-11 { @include mixins.breakpoint(break-xs) { margin-left: 91.66666667% !important; } }
.offset-xs-10 { @include mixins.breakpoint(break-xs) { margin-left: 83.33333333% !important; } }
.offset-xs-9 { @include mixins.breakpoint(break-xs) { margin-left: 75% !important; } }
.offset-xs-8 { @include mixins.breakpoint(break-xs) { margin-left: 66.66666667% !important; } }
.offset-xs-7 { @include mixins.breakpoint(break-xs) { margin-left: 58.33333333% !important; } }
.offset-xs-6 { @include mixins.breakpoint(break-xs) { margin-left: 50% !important; } }
.offset-xs-5 { @include mixins.breakpoint(break-xs) { margin-left: 41.66666667% !important; } }
.offset-xs-4 { @include mixins.breakpoint(break-xs) { margin-left: 33.33333333% !important; } }
.offset-xs-3 { @include mixins.breakpoint(break-xs) { margin-left: 25% !important; } }
.offset-xs-2 { @include mixins.breakpoint(break-xs) { margin-left: 16.66666667% !important; } }
.offset-xs-1 { @include mixins.breakpoint(break-xs) { margin-left: 8.33333333% !important; } }