@use 'vars';
@use 'mixins';

*, :after, :before {
  @include mixins.box-sizing(border-box);
}

html {
  background: transparent;
  font-size: 10px;
  height: 100%;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* Androids */
}

body {
  background-color: #fafafa;
  font-family: 'Calibri', sans-serif !important;

  &.overflow {
    overflow: hidden;
  }
}

.strong {
  font-weight: bold;
}

textarea {
  max-width: 100%;
  font-size: 1.4rem;
}

td,th { vertical-align: middle; }
pre {
  font-size: 1.4rem;
  line-height: 10px;
  font-family: monospace;
  margin-bottom: 10px;
}

select {
  @include mixins.border-radius(3px);
  background-color: #f0f0f0;
  max-width: 350px;
  border: 1px solid #d7d7d7;
  height: 30px;
  padding: 0 0 0 10px;
  color: #444;
  font-size: 12px;
}
hr {
  color: vars.$f3;
  margin: 15px 0;
}
h1,
h2 {
  color: #333;
  font-weight: bold;
}
h1 { font-size: 2rem; }
h2 { font-size: 1.6rem; }
h3,
h4 { font-size: 1.4rem; }
p {
  font-size: 1.4rem;
  line-height: 14px;
  > a, button {
    color: vars.$green;
    text-decoration: none;
    &:hover { color: vars.$green-hover; }
  }
}

strong { font-weight: bold; }

.margin-top {
  margin-bottom: 0;
  margin-top: 10px;
}

.padding-left {
  padding-left: 15px;
}
.padding-bottom {
  padding-bottom: 15px;
}
.hidden { display: none !important; }
.clear { clear: both; }
.blur {
  -webkit-filter: blur(3px);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Blur=3)";
  filter: blur(3px);
}

.left { float: left; }
.right { float: right; }

.body-wrapper {
  width: 100%;
  height:100%;
}

a {
  &.text-link {
    color: vars.$green;
    text-decoration: none;
    &:hover {
      color: vars.$green-hover;
      text-decoration: underline;
    }
  }

  &.disabled {
    :not(.btn, .btn *) {
      color: #d3d3d3 !important;
      cursor: not-allowed;
    }
  }
}

.d {
  &-none {
    display: none !important;
  }

  &-flex {
    display: flex !important;
  }

  &-block {
    display: block !important;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-between {
    justify-content: space-between !important;
  }
  &-around {
    justify-content: space-around !important;
  }
}

.v-hidden {
  visibility: hidden;
}

.border {
  border: 1px solid vars.$e3;

  &-l {
    border-left: 1px solid vars.$e3;
  }

  &-r {
    border-right: 1px solid vars.$e3;
  }

  &-t {
    border-top: 1px solid vars.$e3;
  }

  &-b {
    border-bottom: 1px solid vars.$e3;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }

  &-1 {
    margin: 10px !important;
  }

  &-2 {
    margin: 20px !important;
  }

  &-3 {
    margin: 40px !important;
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }

  &-1 {
    padding: 10px !important;
  }

  &-2 {
    padding: 20px !important;
  }

  &-3 {
    padding: 40px !important;
  }
}

.pl {

  &-0 {
    padding-left: 0 !important;
  }

  &-1 {
    padding-left: 10px !important;
  }

  &-2 {
    padding-left: 20px !important;
  }

  &-3 {
    padding-left: 40px !important;
  }
}

.pr {

  &-0 {
    padding-right: 0 !important;
  }

  &-1 {
    padding-right: 10px !important;
  }

  &-2 {
    padding-right: 20px !important;
  }

  &-3 {
    padding-right: 40px !important;
  }
}

.pb {

  &-0 {
    padding-bottom: 0 !important;
  }

  &-1 {
    padding-bottom: 10px !important;
  }

  &-2 {
    padding-bottom: 20px !important;
  }

  &-3 {
    padding-bottom: 40px !important;
  }
}

.py {

  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  &-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  &-3 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.mt {
  &-1 {
    margin-top: 10px !important;
  }

  &-2 {
    margin-top: 20px !important;
  }

  &-3 {
    margin-top: 40px !important;
  }
}

.mr {
  &-0 {
    margin-right: 0 !important;
  }

  &-1 {
    margin-right: 10px !important;
  }

  &-2 {
    margin-right: 20px !important;
  }

  &-3 {
    margin-right: 40px !important;
  }
}

.ml {
  &-0 {
    margin-left: 0 !important;
  }

  &-1 {
    margin-left: 10px !important;
  }

  &-2 {
    margin-left: 20px !important;
  }

  &-3 {
    margin-left: 40px !important;
  }
}

.mb {
  &-1 {
    margin-bottom: 10px !important;
  }

  &-2 {
    margin-bottom: 20px !important;
  }

  &-3 {
    margin-bottom: 40px !important;
  }
}

.mx {
  &-1 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  &-2 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  &-3 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}
.my {
  &-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  &-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  &-3 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
.f {
  &-13 {
    font-size: 13px;
  }
}

.float {
  &-right {
    float: right !important;
  }

  &-left {
    float: left !important;
  }
}

.l {
  &-30 {
    line-height: 30px;
  }
}

.text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}
